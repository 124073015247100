import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import axios from "axios";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { API_ROOT, webPath } from "../apiconfig";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";

export function GuestComp() {
  const [guestData, setGuestData] = useState(
    JSON.parse(localStorage.getItem("guestData")) || []
  );

  const isLargeScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const dataCompWidth = isLargeScreen ? 500 : 200;

  const fetchGuestData = useCallback(async () => {
    try {
      const response = await axios.get(`${API_ROOT}/api/post/homeinterview`);
      const fetchedData = response.data;
      setGuestData(fetchedData);
    } catch (error) {
      console.error("Error fetching guest data:", error);
    }
  }, []);

  useEffect(() => {
    fetchGuestData();
  }, [fetchGuestData]);

  // Memoize guest data to prevent unnecessary re-renders
  const guestDataMemo = useMemo(() => guestData, [guestData]);

  const swiperRef = useRef(null);

  useEffect(() => {
    if (guestDataMemo.length > 0) {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = `${webPath}${guestDataMemo[0]?.banner_img}`;
      document.head.appendChild(link);
    }
  }, [guestDataMemo]);

  return (
    <div style={{ overflow: "hidden" }}>
      <h3 className="fw-bold py-1 h4 text-center container mt-5 container-max borderB">
        Guest Author
      </h3>

      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-md-12 swiperBox">
            {guestDataMemo.length > 0 && (
              <Swiper
                ref={swiperRef}
                loop={true}
                className="mb-4"
                spaceBetween={10}
                slidesPerView={1}
                slidesPerGroup={1}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                navigation={true}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 10 },
                  768: { slidesPerView: 2, spaceBetween: 20 },
                  1024: { slidesPerView: 3, spaceBetween: 10 },
                  1199: { slidesPerView: 4, spaceBetween: 20 },
                }}
              >
                {guestDataMemo.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className="card">
                      <a
                        className="a-link text-black"
                        href={`/${item.cat_slug}/${item.post_name}`}
                      >
                 

<img
            src={`${webPath}${item.banner_img}?width=${dataCompWidth}`}
            alt={item.post_name}
            loading="lazy"
            width={dataCompWidth} // Explicit width
            height={200} // Explicit height (matches style height)
            style={{ width: "100%", height: "400px", objectFit: "cover" }}
          />
                        <div className="card-body" style={{ height: "157px" }}>
                          <h3 className="card-title h5  fw-bold hoverHead line-clamp">
                            {item?.post_title}
                          </h3>

                          <p
                            className="card-text mt-1"
                            style={{ fontSize: "13px" }}
                          >
                            By{" "}
                            <span className="fw-bold">{item.post_author}</span>{" "}
                            |{" "}
                            {new Date(item.post_date).toLocaleDateString(
                              undefined,
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </p>
                          <p className="card-text mt-1 line-clamp">
                            {item?.post_content}
                          </p>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
