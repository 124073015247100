import React, { useCallback, useState } from "react";

const SkeletonImage = () => (
  <div className="skeleton-loader" style={{ width: "100%", height: "300px", backgroundColor: "#e0e0e0" }}></div>
);

export const DataComp = React.memo(
  ({
    src,
    alt,
    ee,
    h2Title,
    p_Desc,
    post_author,
    post_date,
    withZoom,
    loading,
    imageContent,
    fetchpriority,
    srcSet
  }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false); // Tracks image loading status
    const [hasError, setHasError] = useState(false); // Tracks image loading errors

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    const handleImageLoad = useCallback(() => {
      setIsImageLoaded(true); // Set to true once the image is loaded
    }, []);

    const handleImageError = useCallback((e) => {
      console.error("Image failed to load:", e.target.src); // Log the error
      setHasError(true); // Set error state if the image fails to load
      e.target.src = "/fallback-image.png"; // Use a fallback image
      setIsImageLoaded(true); // Hide the skeleton even if there's an error
    }, []);

    return (
      <div
        className={ee}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "15px",
        }}
      >
        <figure className={withZoom ? "image-container" : ""}>
          {/* Show Skeleton until image is loaded */}
          {!isImageLoaded && !hasError && <SkeletonImage />}
          <img
            src={src}
            srcSet={srcSet}
            className="homeImg"
            alt={alt}
            width="500"
            height="500"
            onError={handleImageError} // Handle error event
            style={{ display: isImageLoaded || hasError ? "block" : "none" }} // Show image only after it's loaded or failed
            onLoad={handleImageLoad} // Trigger image load event
            fetchpriority={fetchpriority} // Optional: image loading priority
            loading={loading} // Lazy load the image
          />
        </figure>
        <div>
          <h2 className="fw-bold h4 hoverHead line-clamp">{h2Title}</h2>
          <p style={{ fontSize: "13px" }}>
            By <span className="fw-bold">{post_author}</span> |{" "}
            {new Date(post_date).toLocaleDateString(undefined, options)}
          </p>
          <p className="just-text line-clamp mt-1" style={{ fontSize: "13px" }}>
            {p_Desc}
          </p>
        </div>
      </div>
    );
  }
);

